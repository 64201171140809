<template>
  <div class="toggle-box mb-1">
    <div class="flex items-center justify-between">
      <span
        v-if="requireTag"
        class="bg-red-100 required-span rounded-lg text-[#DD5928] text-xs px-1.5 py-1.5 mb-1.5"
      >
        Required
      </span>
    </div>
    <div class="flex items-center justify-between">
      <v-switch
        v-model="isChecked"
        :value="modelValue"
        :aria-checked="isChecked"
        :disabled="disabledComponentState"
        class="ml-2"
      ></v-switch>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
    requireTag: {
      type: Boolean,
      default: false,
    },
    disabledComponentState: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      
    };
  },
  computed: {
    isChecked: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("updateValue", value);
      }
    }
  },
};
</script>

<style scoped>
.toggle-box {
  width: 100%;
}
</style>
