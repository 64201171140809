<template>
  <div class="d-flex justify-space-between items-center">
    <v-btn @click.prevent="cancelBtn">Cancel</v-btn>
    <div>
      <v-btn class="mr-5" @click.prevent="backBtn">Back</v-btn>
      <v-btn class="primary" v-model="isUpdated" @click.prevent="nextBtn" @updateModel="isUpdated" :disabled="isActive">{{ btnTitle()
        }}</v-btn>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  inject: ["name"],
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    selectedAssigneeIds: {
      type: Array,
    },
  },
  data() {
    return {
      label: "Next",
      active: false
    };
  },
  computed: {
    getName(){
      return this.name;
    },
    getPermissions() {
      return this.$store.state.access.formData.permissions;
    },
    isActive(){
      if(this.$route.path === "/assign-access"){
        return this.selectedAssigneeIds?.length > 0 ? this.active : !this.active
      }else if(this.$route.path === "/permissions"){
        return this.getPermissions?.length > 0 ? this.active : !this.active
      }
      return this.active;
    },
    isUpdated: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("updateModel", value);
      },
    },
    getFormData() {
      return this.$store.state.access.formData;
    },
  },
  created() {
    this.$store.dispatch("access/listPermissions");
  },
  methods: {
    ...mapActions(["showSnackbar"]),
    cancelBtn() {
      if (this.$route.path === "/permissions") {
        this.$emit("reset");
      }
      if (this.$route.path === "/assign-access") {
        this.$emit("cancel");
      }
    },
    backBtn() {
      if (this.$route.path === "/create-access") {
        this.$router.push("/access-level");
      } else if (this.$route.path === "/permissions") {
        this.$router.push("/create-access");
      } else if (this.$route.path === "/assign-access") {
        this.$router.push("/permissions");
      }
    },

    nextBtn() {
      if (this.$route.path === "/create-access") {
        this.$router.push("/permissions");
      } else if (
        this.$route.path === "/permissions" &&
        this.modelValue === true
      ) {
        this.$router.push("/assign-access");
      }
      if (this.label === "Save") {
        this.$store.dispatch("access/createAccessLevel", this.getFormData);
        this.$router.push("/access-level");
        this.$store.commit("access/SET_FORM_DATA", {
          name: "",
          description: "",
          permissions: [],
          userIds: [],
        })
      }
    },
    btnTitle() {
      if (this.$route.path === "/assign-access") {
        this.label = "Save";
      } else {
        this.label = "Next";
      }
      return this.label;
    },
    closeSaveConfirm() {
      this.confirmDialog = false;
    },
  },
};
</script>
